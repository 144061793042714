import React from 'react'
import { TextField } from '@mui/material'

export interface TextInputProps {
  label: string
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  error?: string | null
  multiline?: boolean
  size?: 'small' | 'medium' | 'large' | number
  editable?: boolean
  secureText?: boolean
  required?: boolean
}

const sizes = {
  small: 200,
  medium: 400,
  large: 600,
}

export const TextInput = ({
  editable,
  label,
  multiline,
  secureText = false,
  onChange,
  value,
  size,
  error,
  required = false,
}: TextInputProps): React.ReactNode => {
  const sx = { width: sizes.small }
  if (size != null) {
    if (typeof size === 'number') {
      sx.width = size
    } else {
      sx.width = sizes[size]
    }
  }
  return (
    <div className={'mb-4'}>
      <TextField
        required={required}
        disabled={editable == null ? false : !editable}
        label={label}
        multiline={multiline != null}
        minRows={10}
        type={secureText ? 'password' : 'text'}
        onChange={onChange}
        value={value}
        size={'small'}
        sx={sx}
      />
      {error == null ? null : <p className={'text-red-500'}>{error}</p>}
    </div>
  )
}
