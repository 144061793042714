import React from 'react'
import { Link } from 'react-router-dom'
import { Checkmark } from '../components/Checkmark'

const PricingTable = (): React.ReactNode => {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <div className="mb-4 tracking-tight font-extrabold text-gray-900 dark:text-white">
            Get Started!
          </div>
          <div className="mb-5 text-4xl font-bold text-gray-500 dark:text-gray-400">
            Make your strata management easier
          </div>
          <div className={'text-white'}>
            30-day free trial. No credit card required.
          </div>
        </div>
        <div className={'flex justify-evenly'}>
          <div className="space-y-8 lg:grid sm:gap-6 xl:gap-10 lg:space-y-0">
            <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 className="mb-4 text-2xl font-semibold">
                {/* TODO: add a name when creating more plans */}
              </h3>
              <div className="flex justify-center items-end">
                <div className="mr-2 text-8xl font-extrabold">$3</div>
                <div className="text-gray-500 dark:text-gray-400">
                  <p>Per unit,</p>
                  <p>per month</p>
                  <p>paid annually</p>
                </div>
              </div>
              <div className={'text-gray-500 mb-4'}>
                or $4 per unit, per month paid monthly
              </div>
              <ul role="list" className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <Checkmark />
                  <span>Unlimited users</span>
                </li>
                <li className="flex items-center space-x-3">
                  <Checkmark />
                  <span>Unlimited units</span>
                </li>
                <li className="flex items-center space-x-3">
                  <Checkmark />
                  <span>Issue Management</span>
                </li>
                <li className="flex items-center space-x-3">
                  <Checkmark />
                  <span>Manage Visitor Parking</span>
                </li>
              </ul>
              <Link
                to={'/signup'}
                className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900"
              >
                Start your free trial
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export const Pricing = (): React.ReactNode => {
  return <PricingTable />
}
