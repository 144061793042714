import React, { useEffect } from 'react'
import './App.css'
import { useNavigate } from 'react-router-dom'
import { AccountResource } from './services/api/accountResource'
import { Landing } from './pages/Landing'
function App(): React.ReactNode {
  const navigate = useNavigate()

  useEffect(() => {
    AccountResource.index()
      .then((response) => {
        if (response.data.data.length === 1) {
          navigate(`/accounts/${response.data.data[0].url_token}/units`)
        } else {
          navigate('/accounts')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [navigate])

  return <Landing />
}

export default App
