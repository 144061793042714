import React from 'react'
import { Form, useNavigate } from 'react-router-dom'
import { TextInput } from '../components/form/textInput'
import { Button, Grid } from '@mui/material'
import { Checkmark } from '../components/Checkmark'
import { AccountResource } from '../services/api/accountResource'

export const Signup = (): React.ReactNode => {
  const navigate = useNavigate()
  const [email, setEmail] = React.useState<string>('')
  const [name, setName] = React.useState<string>('')
  const [accountName, setAccountName] = React.useState<string>('')
  const [humanVerified, setHumanVerified] = React.useState<boolean>(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    AccountResource.create({
      name: accountName,
      owner: {
        name,
        email,
      },
      humanVerified: humanVerified ? '1' : null,
    })
      .then((response) => {
        navigate('/signup/success')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <div className={'max-h-full bg-primary-50 w-full h-full'}>
      <div
        className={'flex justify-around mx-auto pt-4  '}
        style={{ width: 800 }}
      >
        <div className={'pt-6'}>
          <div className={'text-xl font-bold mb-4'}>
            Make strata management easy!
          </div>
          <div className={'flex gap-2 items-center mb-2'}>
            <Checkmark />
            Visitor Parking Management
          </div>
          <div className={'flex gap-2 items-center mb-2'}>
            <Checkmark />
            Alerts and notifications made easy!
          </div>
          <div className={'flex gap-2 items-center mb-2'}>
            <Checkmark />
            Don&apos;t lose track of your issues!
          </div>
        </div>
        <div className="py-6 bg-white rounded-lg" style={{ minWidth: 350 }}>
          <div className="py-6">
            <div>
              <div>
                <div className="mb-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-xl lg:text-xl text-center">
                  Start your free trial
                </div>
                <Form onSubmit={handleSubmit} className={'px-8'}>
                  <TextInput
                    label={'Your Name'}
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value)
                    }}
                    size={300}
                  />
                  <TextInput
                    label={'Your Email'}
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value)
                    }}
                    size={300}
                  />
                  <TextInput
                    label={'Name of your Strata'}
                    value={accountName}
                    onChange={(event) => {
                      setAccountName(event.target.value)
                    }}
                    size={300}
                  />
                  <input
                    type={'hidden'}
                    name={'captcha-verification'}
                    onChange={() => {
                      setHumanVerified(true)
                    }}
                  />
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      size={'large'}
                      sx={{ width: 300 }}
                    >
                      Start your free trial
                    </Button>
                  </Grid>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
