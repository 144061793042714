import { accountScoped } from '../../lib/url'
import { apiClient } from './base'

export interface Document {
  id: string
  name: string
  url: string
}

export interface NewDocument {
  name: string
  file: File
}

export interface DocumentsResponse {
  data: Document[]
  pagination: {
    count: number
    prev_url: string
    next_url: string
    last: number
  }
}

export interface UpdateDocument {
  title: string
  tenant_ids: string[]
  owner_ids: string[]
}

export const DocumentResource = {
  index: async (params?: { page: number }) =>
    await apiClient().get<DocumentsResponse>(accountScoped('documents'), {
      params: { per_page: 15, ...params },
    }),
  create: async (data: NewDocument) => {
    const body = new FormData()
    body.append('document[name]', data.name)
    body.append('document[file]', data.file)
    return await apiClient().post<Document>(accountScoped('documents'), body)
  },
  show: async (id: string) =>
    await apiClient().get<Document>(accountScoped(`documents/${id}`)),
  update: async (id: string, data: UpdateDocument) =>
    await apiClient().put<Document>(accountScoped(`documents/${id}`), {
      unit: { ...data },
    }),
}
