import React, { useEffect, useState } from 'react'
import {
  type User,
  UserResource,
  type UsersResponse,
} from '../../services/api/userResource'
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@mui/icons-material'
import { PageHeader } from '../../components/headings'
import { accountScoped } from '../../lib/url'
import { useNavigate } from 'react-router-dom'
import { CustomTableCell } from '../../components/table'
import { HumanFriendlyRoles } from '../../lib/roles'

export const Index = (): React.ReactNode => {
  const initialUsers: User[] = [] as User[]
  const [users, setUsers] = useState<User[]>(initialUsers)
  const [page, setPage] = useState(1)
  const initialPagination: UsersResponse['pagination'] = {
    count: 0,
    last: 0,
    next_url: '',
    prev_url: '',
  }
  const [pagination, setPagination] = useState(initialPagination)
  const navigate = useNavigate()

  useEffect(() => {
    UserResource.index({ page })
      .then((response) => {
        setUsers(response.data.data)
        setPagination(response.data.pagination)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [page])
  const table: React.ReactNode = (
    <TableContainer>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <CustomTableCell label={'Name'} />
            <CustomTableCell label={'Email'} />
            <CustomTableCell label={'Roles'} />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow
              key={user.name}
              className={'cursor-pointer'}
              onClick={() => {
                navigate(accountScoped(`/users/${user.id}/edit`))
              }}
            >
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                {user.roles.map((v) => HumanFriendlyRoles(v.roles)).join(', ')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>
              <IconButton
                onClick={() => {
                  setPage(1)
                }}
                disabled={page === 1}
              >
                <FirstPage />
              </IconButton>
              <IconButton
                onClick={() => {
                  setPage(page - 1)
                }}
                disabled={page === 1}
              >
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton
                onClick={() => {
                  setPage(page + 1)
                }}
                disabled={page === pagination.last}
              >
                <KeyboardArrowRight />
              </IconButton>
              <IconButton
                onClick={() => {
                  setPage(pagination.last)
                }}
                disabled={page === pagination.last}
              >
                <LastPage />
              </IconButton>
              Page {page} of {pagination.last}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )

  return (
    <div>
      <PageHeader title={'Users'} />
      <Button
        onClick={() => {
          navigate(accountScoped('users/new'))
        }}
      >
        New User
      </Button>
      {table}
    </div>
  )
}
