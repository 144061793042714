import {
  type Document,
  DocumentResource,
} from '../../services/api/documentResource'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const Show = (): React.ReactNode => {
  const [document] = useState<Document | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    setLoading(true)
    if (id == null) {
      setLoading(false)
      return
    }
    DocumentResource.show(id)
      .then((response) => {
        // setDocument(response.data.)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching document', error)
        setLoading(false)
      })
  }, [id])

  if (loading) {
    return <div>Loading...</div>
  }

  if (document == null) {
    return <div>Document not found</div>
  }

  return (
    <div>
      <h1>{document.name}</h1>
      <a href={document.url} target={'_blank'} rel="noreferrer">
        Download
      </a>
    </div>
  )
}
