import { accountScoped } from '../../lib/url'
import { type ErrorResponse } from './errorResponse'
import { apiClient } from './base'

export interface User {
  id: string
  name: string
  email: string
  roles: Array<{
    roles: string
  }>
  avatar_url: string | null
  errors?: ErrorResponse
}

export interface NewUser {
  name: string
  email: string
  roles: string[]
}

export interface UsersResponse {
  data: User[]
  pagination: {
    count: number
    prev_url: string
    next_url: string
    last: number
  }
}

interface UpdateUser {
  name: string | null
  avatar: File | string | null
}

interface RolesResponse {
  roles: string[]
}

export const UserResource = {
  index: async (params?: { page?: number; context?: string }) =>
    await apiClient().get<UsersResponse>(accountScoped('users'), { params }),
  create: async (data: NewUser) => {
    return await apiClient().post<User>(accountScoped('users'), {
      user: data,
    })
  },
  confirm: async (token: string, password: string) => {
    const formData = new FormData()
    formData.append('confirmation_token', token)
    formData.append('password', password)
    return await apiClient().put(`/users/confirm`, formData)
  },
  update: async (id: string, data: UpdateUser) => {
    const formData = new FormData()
    if (data.name != null) {
      formData.append('user[name]', data.name)
    }
    if (data.avatar != null) {
      formData.append('user[avatar]', data.avatar)
    }
    return await apiClient().put('users', formData)
  },
  current: async () => {
    return await apiClient().get<User>(accountScoped('users/current'))
  },
  roles: async () => {
    return await apiClient().get<RolesResponse>(accountScoped('users/roles'))
  },
}
