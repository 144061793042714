import React, { useEffect, useState } from 'react'
import { type Issue, IssueResource } from '../../services/api/issueResource'
import { useNavigate } from 'react-router-dom'
import { extractUrlToken } from '../../lib/url'
import { TabContainer } from '../../components/tabs'
import { PageHeader } from '../../components/headings'
import { Button, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { StatusColors, StatusLabel } from './statusLabel'
import { useSession } from '../../lib/session'

function IssueTable(props: { issues: Issue[] }): React.ReactNode {
  const cellStyles = {
    auto: {},
    large: {
      width: 200,
    },
    small: {
      width: 50,
    },
    medium: {
      width: 100,
    },
  }

  return (
    <table className={'w-full'}>
      <thead>
        <tr>
          <th className={'text-left'} style={cellStyles.large}>
            Issue
          </th>
          <th className={'text-left'} style={cellStyles.small}>
            Status
          </th>
          <th className={'text-left'} style={cellStyles.medium}>
            Reported by
          </th>
          <th className={'text-left'} style={cellStyles.medium}>
            Assigned to
          </th>
          <th className={'text-left'} style={cellStyles.small}>
            Created
          </th>
        </tr>
      </thead>
      <tbody>
        {props.issues.map((issue: Issue) => {
          const unit =
            issue.reporter_units?.[0] != null
              ? `(${issue.reporter_units?.[0]})`
              : ''
          return (
            <tr key={issue.id}>
              <td>
                <a href={`/accounts/${extractUrlToken()}/issues/${issue.id}`}>
                  {issue.title}
                </a>
              </td>
              <td>
                <StatusLabel status={issue.status} />
              </td>
              <td>
                {issue.reporter?.name != null ? (
                  <>
                    {issue.reporter.name}{' '}
                    <span className={'font-medium text-gray-500 text-sm'}>
                      {unit}
                    </span>
                  </>
                ) : (
                  '-'
                )}
              </td>
              <td>{issue.assigned_to?.name ?? '-'}</td>
              <td>
                <Tooltip title={dayjs(issue.created_at).format('LLL')}>
                  <span>
                    {
                      // @ts-expect-error the relativeTime plugin might not have the `to` method
                      dayjs().to(dayjs(issue.created_at))
                    }
                  </span>
                </Tooltip>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const IssueTab = ({
  status,
  tabStyle = 'table',
}: {
  status: string
  tabStyle: 'table' | 'list'
}): React.ReactNode => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-argument
  dayjs.extend(require('dayjs/plugin/relativeTime'))
  const [issues, setIssues] = useState<Issue[]>([] as Issue[])
  useEffect(() => {
    IssueResource.index({
      status,
    })
      .then((res) => {
        setIssues(res.data.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [status])

  return (
    <div className={'grid grid-cols-12 gap-4'}>
      <div className={'col-span-12'}>
        {tabStyle === 'table' && <IssueTable issues={issues} />}
        {tabStyle === 'list' &&
          issues.map((issue) => {
            return (
              <a
                key={issue.id}
                href={`/accounts/${extractUrlToken()}/issues/${issue.id}`}
              >
                <div
                  className={
                    'p-2 border border-1 rounded-lg shadow shadow-sm mb-4'
                  }
                >
                  <div className={'flex gap-1 items-center'}>
                    <div
                      className={`rounded-full w-3 h-3 mr-2 ${
                        StatusColors[issue.status]
                      }`}
                    ></div>
                    <strong>{issue.title}</strong>
                    <Tooltip title={dayjs(issue.created_at).format('LLL')}>
                      <span>
                        {
                          // @ts-expect-error the relativeTime plugin might not have the `to` method
                          dayjs().to(dayjs(issue.created_at))
                        }
                      </span>
                    </Tooltip>
                  </div>
                  <div>
                    {issue.description.substring(0, 100)}
                    {issue.description.length > 100 ? '...' : ''}
                  </div>
                </div>
              </a>
            )
          })}
      </div>
    </div>
  )
}

export const Index = (): React.ReactNode => {
  const navigate = useNavigate()
  const { currentUser } = useSession()
  const roles = currentUser?.roles ?? []
  const showTable =
    roles.includes('strata_manager') || roles.includes('council')
  const tabStyle = showTable ? 'table' : 'list'
  return (
    <div>
      <PageHeader title={'Issues'} />
      <Button
        className={'col-span-12 text-left'}
        onClick={() => {
          navigate('new')
        }}
      >
        New Issue
      </Button>
      <TabContainer
        tabs={[
          {
            label: 'All',
            content: <IssueTab status={'all'} tabStyle={tabStyle} />,
          },
          {
            label: 'Pending',
            content: <IssueTab status={'pending'} tabStyle={tabStyle} />,
          },
          {
            label: 'In Progress',
            content: <IssueTab status={'in_progress'} tabStyle={tabStyle} />,
          },
          {
            label: 'Resolved',
            content: <IssueTab status={'resolved'} tabStyle={tabStyle} />,
          },
        ]}
      />
    </div>
  )
}
