import axios, { type AxiosResponse } from 'axios'
import { getBaseURL } from './base'

export const login = async (
  email: string,
  password: string,
): Promise<AxiosResponse> => {
  return await axios
    .post(
      `${getBaseURL()}/users/sign_in`,
      {
        user: {
          email,
          password,
        },
      },
      {
        headers: {
          Accept: '*/*',
        },
      },
    )
    .then((response) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      localStorage.setItem('Authorization', response.headers.authorization)
      return response
    })
}
