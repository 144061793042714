export const accountScoped = (urlToScope: string): string => {
  const urlToken = extractUrlToken()
  // remove starting slash if present
  urlToScope = urlToScope[0] === '/' ? urlToScope.slice(1) : urlToScope
  urlToScope = urlToScope.length > 0 ? `/${urlToScope}` : `${urlToScope}`
  return `/accounts/${urlToken}${urlToScope}`
}

export const extractUrlToken = (): string => {
  const url = window.location.href
  // extracting from url of the form https://www.example.com/accounts/123456/parking_requests
  return url.split('/')[4]
}

export const accountUnscoped = (url: string): string => {
  // removed url segment including account scope /accounts/:token
  const regex = /.*\/accounts\/[a-zA-Z0-9]+\/?/
  return url.replace(regex, '')
}
