import React from 'react'
import { ImageList } from './imageList'
import { UploadImage } from './uploadImage'

export const ImageAttachments = (props: {
  onImagesAdded: (event: React.ChangeEvent<HTMLInputElement>) => void
  onImageDelete?: (image: File | { id: string; url: string }) => void
  images: Array<File | { id: string; url: string }>
}): React.ReactNode => {
  return (
    <div>
      <UploadImage multiple={true} onChange={props.onImagesAdded} />
      <ImageList files={props.images} onDelete={props.onImageDelete} />
    </div>
  )
}
