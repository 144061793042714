import React, { useRef } from 'react'
import { CloudUpload } from '@mui/icons-material'

export function UploadImage(props: {
  multiple: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}): React.ReactNode {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div className={'flex gap-2 align-top mb-4'}>
      <div>
        <input
          ref={inputRef}
          type="file"
          multiple={props.multiple}
          accept="image/*"
          style={{ display: 'none' }}
          onChange={props.onChange}
        />
        <span
          onClick={() => {
            if (inputRef.current != null) {
              inputRef.current.click()
            }
          }}
          className={
            'flex gap-1 align-center bg-gray-400 p-1 rounded px-3 cursor-pointer p-2'
          }
        >
          <CloudUpload className={'text-gray-700'} />
          <div>Attach</div>
        </span>
      </div>
    </div>
  )
}
