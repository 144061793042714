import React from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from '@mui/material'
import { TextInput } from '../components/form/textInput'
import { UserResource } from '../services/api/userResource'

export const ConfirmationForm = (): React.ReactNode => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [password, setPassword] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [acceptTerms, setAcceptTerms] = React.useState(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    const token = searchParams.get('token')
    if (token == null) {
      setErrorMessage('Invalid token')
      return
    }
    UserResource.confirm(token, password)
      .then(() => {
        setSearchParams({ token: '' })
        navigate('/login')
      })
      .catch((error) => {
        console.error(error)
      })
  }
  return (
    <>
      <div className={'mx-auto mt-8 pl-10'} style={{ width: 600 }}>
        <div className="text-3xl font-bold mb-4">Confirm your account</div>
        <div className="text-lg mb-4">
          Please confirm your account by setting a password.
        </div>
      </div>
      <div className={'m-auto'} style={{ width: 300 }}>
        <form className="" onSubmit={handleSubmit}>
          <TextInput
            secureText={true}
            label={'Password'}
            value={password}
            onChange={(event) => {
              setPassword(event.target.value)
            }}
          />
          {errorMessage == null ? null : (
            <p className={'text-red-500'}>{errorMessage}</p>
          )}
          <div className="mb-4">
            <input
              type="checkbox"
              checked={acceptTerms}
              onChange={(event) => {
                setAcceptTerms(event.target.checked)
              }}
            />
            <label className={'ml-2 text-sm'}>
              By setting the account, you accept the{' '}
              <Link
                to={'/terms'}
                className={'underline text-blue-700'}
                target={'_blank'}
              >
                terms and conditions
              </Link>
            </label>
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!acceptTerms}
          >
            Login
          </Button>
        </form>
      </div>
    </>
  )
}
