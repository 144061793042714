import { accountScoped } from '../../lib/url'
import { apiClient } from './base'

export interface Unit {
  id: string
  title: string
  created_at: string
  owners: Array<{
    id: string
    name?: string
    email?: string
  }>
  tenants: Array<{
    id: string
    name?: string
    email?: string
  }>
}

export interface NewUnit {
  title: string
}

export interface UnitsResponse {
  data: Unit[]
  pagination: {
    count: number
    prev_url: string
    next_url: string
    last: number
  }
}

export interface UpdateUnit {
  title: string
  tenant_ids: string[]
  owner_ids: string[]
}

export const UnitResource = {
  index: async (params?: { page: number }) =>
    await apiClient().get<UnitsResponse>(accountScoped('units'), {
      params: { per_page: 15, ...params },
    }),
  create: async (data: NewUnit) =>
    await apiClient().post<Unit>(accountScoped('units'), { unit: { ...data } }),
  show: async (id: string) =>
    await apiClient().get<Unit>(accountScoped(`units/${id}`)),
  update: async (id: string, data: UpdateUnit) =>
    await apiClient().put<Unit>(accountScoped(`units/${id}`), {
      unit: { ...data },
    }),
}
