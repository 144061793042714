import { apiClient } from './base'
import { accountScoped } from '../../lib/url'

export interface ParkingRequest {
  id: string
  license_plate: string
  start_at: string
  end_at: string
  status: string
}

export interface NewParkingRequest {
  license_plate: string
  start_at: string
}

export interface ParkingRequestsResponse {
  data: ParkingRequest[]
  meta: { pagination: { count: number; prev_url: string; next_url: string } }
}

export const ParkingRequestResource = {
  index: async (params?: Record<string, unknown>) => {
    return await apiClient().get<ParkingRequestsResponse>(
      accountScoped('parking_requests'),
      { params },
    )
  },
  create: async (parkingRequest: NewParkingRequest) => {
    return await apiClient().post<ParkingRequest>(
      accountScoped('parking_requests'),
      {
        parking_request: parkingRequest,
      },
    )
  },
}
