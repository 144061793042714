import React, { useEffect, useState } from 'react'

import { Form, useNavigate } from 'react-router-dom'
import { TextInput } from '../../components/form/textInput'
import { ImageAttachments } from './imageAttachments'
import { Button, FormControl } from '@mui/material'
import { StatusLabel } from './statusLabel'
import { CommentsBlock } from '../comments/comments'
import { Dropdown } from '../../components/dropdown'
import { UserResource } from '../../services/api/userResource'
import {
  type AssignedTo,
  IssueResource,
  type Reporter,
} from '../../services/api/issueResource'
import { RoleCan } from '../../components/roleCan'
import { useSession } from '../../lib/session'
import { extractUrlToken } from '../../lib/url'
import { type AxiosError } from 'axios'
import { toast } from 'react-toastify'

type Status = 'pending' | 'in_progress' | 'resolved'

const UserSelector = (props: {
  value: AssignedTo | null
  onChange: (assignedTo: AssignedTo) => void
}): React.ReactNode => {
  const [assignableUsers, setAssignableUsers] = useState<AssignedTo[] | null>(
    null,
  )

  useEffect(() => {
    UserResource.index({ context: 'issue_assignment' })
      .then((res) => {
        setAssignableUsers(res.data.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const renderOption = (assignedTo: AssignedTo): React.ReactNode => {
    if (assignedTo == null) return <div>Unassigned</div>
    return <div>{assignedTo.name}</div>
  }

  if (assignableUsers == null) {
    return null
  }

  const options = assignableUsers.map((user) => {
    return user
  })

  return (
    <FormControl>
      <Dropdown<AssignedTo>
        keyFunction={(option) => option.id}
        options={options}
        renderOption={renderOption}
        value={props.value}
        onOptionChange={props.onChange}
      />
    </FormControl>
  )
}

function StatusSelector(props: {
  value: Status
  onChange: (status: Status) => void
}): React.ReactNode {
  const options: Status[] = ['pending', 'in_progress', 'resolved']

  const renderOption = (
    option: 'pending' | 'in_progress' | 'resolved',
  ): React.ReactNode => {
    return <StatusLabel status={option} />
  }

  return (
    <FormControl>
      <Dropdown<Status>
        keyFunction={(option) => option}
        options={options}
        renderOption={renderOption}
        value={props.value}
        onOptionChange={props.onChange}
      />
    </FormControl>
  )
}

export function IssueForm({
  issue,
}: {
  issue: {
    id: string
    title: string
    description: string
    status: string
    images: Array<File | { id: string; url: string }>
    assigned_to: AssignedTo | null
    reporter: Reporter
  } | null
}): React.ReactNode {
  const navigate = useNavigate()

  const [title, setTitle] = useState<string>(issue?.title ?? '')
  const [description, setDescription] = useState<string>(
    issue?.description ?? '',
  )
  const [errors, setErrors] = useState<string[] | null>(null)
  const [status, setStatus] = useState<Status>(
    (issue?.status as Status) ?? 'pending',
  )
  const [images, setImages] = useState<
    Array<File | { id: string; url: string }>
  >((issue?.images ?? []) as Array<File | { id: string; url: string }>)
  const [assignedTo, setAssignedTo] = useState<AssignedTo | null>(
    issue?.assigned_to ?? null,
  )
  const { currentUser } = useSession()

  const onNewSubmit = ({
    title,
    description,
    images,
  }: {
    title: string
    description: string
    images: Array<File | { id: string; url: string }>
    setErrors: (errors: string[]) => void
  }): void => {
    IssueResource.create({
      title,
      description,
      images: images as File[],
    })
      .then(() => {
        toast.success('Issue created')
        navigate(`/accounts/${extractUrlToken()}/issues`)
      })
      .catch((error: AxiosError<string[]>) => {
        setErrors(error.response?.data ?? null)
      })
  }

  const onUpdateSubmit = (issue: {
    id: string
    title: string
    status?: string
    description: string
    images: Array<File | { id: string; url: string }>
    assigned_to_id: string | null
  }): void => {
    IssueResource.update(issue.id, issue)
      .then(() => {
        toast.success('Issue updated')
        navigate(`/accounts/${extractUrlToken()}/issues`)
      })
      .catch((error: AxiosError<string[]>) => {
        setErrors(error.response?.data ?? null)
      })
  }

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault()
    if (event.target.files != null) {
      const newImages = Array.from(event.target.files)
      setImages([...images, ...newImages])
    }
  }

  const handleOnImageDelete = (
    image: File | { id: string; url: string },
  ): void => {
    setImages(images.filter((img) => img !== image))
  }

  return (
    <>
      <Form
        className={'mt-4'}
        onSubmit={(event) => {
          event.preventDefault()
          if (issue?.id == null) {
            onNewSubmit({
              title,
              description,
              images,
              setErrors,
            })
          } else {
            onUpdateSubmit({
              id: issue.id,
              title,
              description,
              status,
              images,
              assigned_to_id: assignedTo?.id ?? null,
            })
          }
        }}
      >
        {errors != null && errors.length > 0 && (
          <ul
            className={
              'list-disc text-gray-700 bg-red-300 px-4 py-2 mb-2 rounded'
            }
          >
            {errors.map((e, index) => (
              <li key={index} className={'ml-2'}>
                {e}
              </li>
            ))}
          </ul>
        )}
        <RoleCan allowedRoles={['strata_manager', 'council']}>
          <div className={'flex items-start mb-4'}>
            <div className={'flex items-center'}>
              <span className={'mr-2'}>Assigned to:</span>
              <UserSelector
                value={issue?.assigned_to ?? null}
                onChange={(assignedTo) => {
                  setAssignedTo(assignedTo)
                }}
              />
            </div>

            <div className={'flex items-center'}>
              <span className={'mr-2'}>Status:</span>
              <StatusSelector
                value={status}
                onChange={(status) => {
                  setStatus(status)
                }}
              />
            </div>
          </div>
        </RoleCan>

        <TextInput
          editable={
            issue?.id == null || currentUser?.id === issue?.reporter?.id
          }
          label={'Title'}
          value={title}
          onChange={(event) => {
            setTitle(event.target.value)
          }}
          size={'medium'}
        />
        <TextInput
          editable={
            issue?.id == null || currentUser?.id === issue?.reporter?.id
          }
          label={'Description'}
          multiline
          value={description}
          onChange={(event) => {
            setDescription(event.target.value)
          }}
          size={'medium'}
        />
        <div className={'mt-4'}>
          <ImageAttachments
            onImagesAdded={onImageChange}
            images={images}
            onImageDelete={handleOnImageDelete}
          />
        </div>
        <div className={'mt-4'}>
          <Button variant={'contained'} type={'submit'} value={'Submit'}>
            {issue?.id != null ? 'Update' : 'Create'}
          </Button>
        </div>
        <hr className={'mt-4 border border-2'} />
      </Form>
      {issue?.id != null && (
        <CommentsBlock commentableId={issue.id} commentableType={'Issue'} />
      )}
    </>
  )
}
