import React, { useEffect, useState } from 'react'
import { Form, useNavigate, useParams } from 'react-router-dom'
import { UnitResource } from '../../services/api/unitResource'
import { accountScoped } from '../../lib/url'
import { Autocomplete, Button, TextField } from '@mui/material'
import { type User, UserResource } from '../../services/api/userResource'
import { type AxiosError } from 'axios'
import { PageHeader } from '../../components/headings'
import { Float } from '../../components/float'
import { TextInput } from '../../components/form/textInput'

/**
 * Returns a new array with only the unique values from the original array. Assumes that the original array is sorted.
 * @param values
 * @param equality
 */
function distinct<T>(values: T[], equality: (a: T, b: T) => boolean): T[] {
  return values.reduce<T[]>((acc, value) => {
    if (acc.length === 0 || !equality(acc[acc.length - 1], value)) {
      acc.push(value)
    }
    return acc
  }, [])
}

export const Edit = (): React.ReactNode => {
  const [unit, setUnit] = useState({ title: '' })
  const [title, setTitle] = useState(unit.title)
  const [allUsers, setAllUsers] = useState([] as User[])
  const [owners, setOwners] = useState([] as User[])
  const [tenants, setTenants] = useState([] as User[])
  const navigate = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    if (id != null) {
      UnitResource.show(id)
        .then((response) => {
          setUnit(response.data)
          setTitle(response.data.title)
        })
        .catch((error: AxiosError) => {
          console.error(error)
        })
    }
  }, [id])

  useEffect(() => {
    UserResource.index()
      .then((response) => {
        const distinctUsers = distinct<User>(
          response.data.data,
          (user1, user2) => user1.id === user2.id,
        )
        setAllUsers(distinctUsers)
      })
      .catch((error: AxiosError) => {
        console.error(error)
      })
  }, [])

  const handleSubmit = (): void => {
    if (id == null) {
      console.error('id is not defined while updating a unit')
      return
    }
    UnitResource.update(id, {
      title,
      owner_ids: owners.map((owner) => owner.id),
      tenant_ids: tenants.map((tenant) => tenant.id),
    })
      .then(() => {
        navigate(accountScoped('units'))
      })
      .catch((error: AxiosError) => {
        console.error(error)
      })
  }

  return (
    <Float>
      <PageHeader title={`Edit ${unit.title}`} />
      <Form
        className={'mt-4'}
        onSubmit={(event) => {
          handleSubmit()
        }}
      >
        <TextInput
          label={'Title'}
          value={title}
          onChange={(event) => {
            setTitle(event.target.value)
          }}
        />
        <div className={'mb-4'}>
          <Autocomplete
            multiple
            limitTags={2}
            options={allUsers}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Owners" size={'small'} />
            )}
            onChange={(event, value) => {
              setOwners(value)
            }}
            sx={{ width: 200 }}
          />
        </div>
        <div className={'mb-4'}>
          <Autocomplete
            multiple
            limitTags={2}
            options={allUsers}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Tenants" size={'small'} />
            )}
            onChange={(event, value) => {
              setTenants(value)
            }}
            sx={{ width: 200 }}
          />
        </div>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Form>
    </Float>
  )
}
