import { apiClient } from './base'
import { accountScoped } from '../../lib/url'

export interface Account {
  id: string
  name: string
  url_token: string
}

export interface AccountsResponse {
  data: Account[]
}

export interface NewAccount {
  name: string
  owner: {
    name: string
    email: string
    password?: string
  }
  humanVerified: '1' | null
}

export const AccountResource = {
  index: async () => await apiClient().get<AccountsResponse>('/accounts'),
  create: async (account: NewAccount) => {
    await apiClient()
      .post('/accounts', { account })
      .then((res) => {})
      .catch((err) => {
        console.error(err)
      })
  },
  show: async () => {
    return await apiClient().get(accountScoped(''))
  },
}
