import React from 'react'

export const PageHeader = ({
  title,
  subtitle,
}: {
  title: string
  subtitle?: string
}): React.ReactNode => {
  return (
    <div className={'flex flex-col'}>
      <h1 className={'text-2xl font-bold text-headers-500'}>{title}</h1>
      {subtitle == null ? null : <p className={'text-gray-500'}>{subtitle}</p>}
    </div>
  )
}
