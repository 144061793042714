import React, { useEffect, useState } from 'react'
import {
  type Unit,
  UnitResource,
  type UnitsResponse,
} from '../../services/api/unitResource'
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { accountScoped } from '../../lib/url'
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@mui/icons-material'
import { PageHeader } from '../../components/headings'
import { CustomTableCell } from '../../components/table'

export const Index = (): React.ReactNode => {
  const [units, setUnits] = useState([] as Unit[])
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const initialPagination: UnitsResponse['pagination'] = {
    count: 0,
    last: 0,
    prev_url: '',
    next_url: '',
  }
  const [pagination, setPagination] = useState(initialPagination)

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }
    return new Date(dateString).toLocaleDateString('en-US', options)
  }

  useEffect(() => {
    UnitResource.index({ page })
      .then((response) => {
        setUnits(response.data.data)
        setPagination(response.data.pagination)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [page])

  const table = (
    <TableContainer>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <CustomTableCell label={'Title'} />
            <CustomTableCell label={'Added on'} />
            <CustomTableCell label={'Owner'} />
            <CustomTableCell label={'Tenant'} />
          </TableRow>
        </TableHead>
        <TableBody>
          {units.map((unit) => (
            <TableRow
              className={'cursor-pointer hover:bg-gray-100'}
              key={unit.title}
              onClick={() => {
                navigate(accountScoped(`units/${unit.id}`))
              }}
            >
              <TableCell>{unit.title}</TableCell>
              <TableCell>
                <Tooltip title={unit.created_at}>
                  <span>{formatDate(unit.created_at)}</span>
                </Tooltip>
              </TableCell>
              <TableCell>{unit.owners[0]?.name ?? '-'}</TableCell>
              <TableCell>{unit.tenants[0]?.name ?? '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>
              Page {page} of {pagination.last}
              <IconButton
                onClick={() => {
                  setPage(1)
                }}
                disabled={page === 1}
              >
                <FirstPage />
              </IconButton>
              <IconButton
                onClick={() => {
                  setPage(page - 1)
                }}
                disabled={page === 1}
                aria-label="previous page"
              >
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton
                onClick={() => {
                  setPage(page + 1)
                }}
                disabled={page === pagination.last}
                aria-label="previous page"
              >
                <KeyboardArrowRight />
              </IconButton>
              <IconButton
                onClick={() => {
                  setPage(pagination.last)
                }}
                disabled={page === pagination.last}
              >
                <LastPage />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
  return (
    <div>
      <PageHeader title={'Units'} />
      <Button
        onClick={() => {
          navigate(accountScoped('units/new'))
        }}
      >
        New Unit
      </Button>
      {table}
    </div>
  )
}
