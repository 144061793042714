import React from 'react'
import { ImageAttachment } from '../../components/imageAttachment'

export const ImageList = (props: {
  files: Array<File | { id: string; url: string }>
  onDelete?: (file: File | { id: string; url: string }) => void
}): React.ReactNode => {
  const { files, onDelete } = props
  return (
    <div>
      {files?.map((image, index) => {
        let imageParams: { src: string; alt: string }
        if (image instanceof File) {
          imageParams = { src: URL.createObjectURL(image), alt: image.name }
        } else {
          imageParams = { src: image.url, alt: '' }
        }
        return (
          <ImageAttachment
            src={imageParams.src}
            alt={imageParams.alt}
            key={index}
            onDelete={() => {
              if (onDelete != null) {
                onDelete(image)
              }
            }}
          />
        )
      })}
    </div>
  )
}
