import { accountScoped } from '../../lib/url'
import { apiClient } from './base'

export interface Comment {
  id: string
  commentable_id: string
  commentable_type: string
  content: string
  created_at: string
  updated_at: string
  commenter: {
    id: string
    avatar_url: string
    name: string
  }
}

export interface NewComment {
  commentable_id: string
  commentable_type: string
  content: string
}

export interface CommentsResponse {
  data: Comment[]
  meta: { pagination: { count: number; prev_url: string; next_url: string } }
}

export const CommentResource = {
  index: async ({
    commentableId,
    commentableType,
  }: {
    commentableType: string
    commentableId: string
  }) =>
    await apiClient().get<CommentsResponse>(
      accountScoped(
        `comments?commentable_type=${commentableType}&commentable_id=${commentableId}`,
      ),
    ),
  create: async (data: NewComment) => {
    return await apiClient().post<Comment>(accountScoped('comments'), data)
  },
  delete: async (id: string) => {
    return await apiClient().delete(accountScoped(`comments/${id}`))
  },
}
