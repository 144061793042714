import React, { useEffect, useState } from 'react'
import { Float } from '../../components/float'
import { UserResource } from '../../services/api/userResource'
import { TextInput } from '../../components/form/textInput'
import { Button } from '@mui/material'
import { useSession } from '../../lib/session'
import { UploadImage } from '../issues/uploadImage'
import { Person } from '@mui/icons-material'
import { Form } from 'react-router-dom'
import * as PusherPushNotifications from '@pusher/push-notifications-web'
import { getBaseURL } from '../../services/api/base'

const PushNotificationButton = (): React.ReactNode => {
  const { currentUser: user } = useSession()
  const handleClick = (): void => {
    const instanceId = process.env.REACT_APP_PUSHER_BEAMS_INSTANCE_ID
    if (instanceId == null) {
      console.error('Notifications not not enabled due to a misconfiguration.')
      return
    }
    const beamsClient = new PusherPushNotifications.Client({
      instanceId,
    })

    if (user?.id == null) {
      console.error('User not found')
      return
    }

    beamsClient
      .start()
      .then(async () => await beamsClient.getDeviceId())
      .then((deviceId) => {
        const tokenProvider = new PusherPushNotifications.TokenProvider({
          url: `${getBaseURL()}/devices`,
          queryParams: {
            device_id: deviceId,
          },
          headers: {
            authorization: localStorage.getItem('Authorization') ?? '',
          },
        })
        console.log('Successfully registered with Beams. Device ID:', deviceId)
        beamsClient
          .setUserId(user?.id.toString(), tokenProvider)
          .then(console.log)
          .catch(console.error)
      })
      .catch(console.error)
  }

  return (
    <button
      className={'border rounded-md bg-indigo-950 text-gray-200 p-2'}
      onClick={handleClick}
    >
      Enable Push Notifications
    </button>
  )
}

export const EditProfile = (): React.ReactNode => {
  const { currentUser: user, featureFlags } = useSession()
  const [name, setName] = useState<string>(user?.name ?? '')
  const [avatar, setAvatar] = useState<File | string | null>(
    user?.avatar_url ?? null,
  )

  useEffect(() => {
    setName(user?.name ?? '')
    setAvatar(user?.avatar_url ?? null)
  }, [user])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    if (user?.id == null) return

    UserResource.update(user.id, {
      name: name ?? null,
      avatar: avatar ?? null,
    })
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })
  }
  const imageSize = {
    width: 100,
    height: 100,
  }

  let imageParams: { src: string; alt: string }
  if (avatar instanceof File) {
    imageParams = { src: URL.createObjectURL(avatar), alt: avatar.name }
  } else {
    imageParams = { src: user?.avatar_url ?? '', alt: '' }
  }

  return (
    <Float>
      {featureFlags?.push_notifications === true && (
        <div className={'mb-4'}>
          <PushNotificationButton />
        </div>
      )}
      <Form onSubmit={handleSubmit} className={'mb-4'}>
        <TextInput
          label={'Name'}
          value={name}
          onChange={(event) => {
            setName(event.target.value)
          }}
        />
        <div className={'mb-4'}>
          {user?.avatar_url == null && avatar == null && (
            <Person style={imageSize} />
          )}
          {!(user?.avatar_url == null && avatar == null) && (
            <img src={imageParams.src} style={imageSize} />
          )}
          <UploadImage
            multiple={false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.preventDefault()
              if (event.target.files == null) return
              setAvatar(event.target.files[0])
            }}
          />
        </div>
        <div className={'mt-4'}>
          <Button variant={'contained'} type={'submit'} value={'Submit'}>
            Update
          </Button>
        </div>
      </Form>
    </Float>
  )
}
