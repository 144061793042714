import React, { type FormEvent } from 'react'
import { Link } from 'react-router-dom'
import { prospectResource } from '../services/api/prospectResource'

const ProspectForm = (): React.ReactNode => {
  const [prospectEmail, setProspectEmail] = React.useState('')
  const [success, setSuccess] = React.useState(false)

  return (
    <>
      {success && (
        <div className={'font-medium text-green-600'}>
          Thank you for sharing your email. We will contact you soon!
        </div>
      )}

      <form
        className={'flex justify-center gap-2 align-middle h-12'}
        onSubmit={(event: FormEvent) => {
          event.preventDefault()
          prospectResource
            .create({ email: prospectEmail })
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                setSuccess(true)
              }
            })
            .catch((error) => {
              console.error(error)
            })
        }}
      >
        <input
          style={{ width: '300px' }}
          className={'border border-indigo-950 rounded-lg p-2'}
          type={'text'}
          placeholder={'Your email address'}
          value={prospectEmail}
          onChange={(event) => {
            setProspectEmail(event.target.value)
          }}
        />
        <button
          className={
            'bg-indigo-950 text-gray-300 rounded-lg py-0 px-4 m-0 inline-block'
          }
        >
          Request demo
        </button>
      </form>
    </>
  )
}

const LandingBody = (): React.ReactNode => {
  return (
    <div className="max-w-5xl mx-auto py-6 sm:px-6 lg:px-8">
      <div
        className={
          'mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl text-center'
        }
      >
        One place for all Strata Management
      </div>
      <div className={'text-xl px-32 text-justify'}>
        Get rid of spreadsheets, emails, and outdated software. Start your{' '}
        <Link to={'/signup'} className={'text-blue-700 underline font-bold'}>
          free trial
        </Link>{' '}
        today. No credit card required
      </div>
      <div className={'flex flex-col gap-2 items-center mt-8'}>
        <div className={'text-xl font-bold'}>
          Share your email to book a demo and learn more about the product!
        </div>
        <ProspectForm />
      </div>
    </div>
  )
}

export const Landing = (): React.ReactNode => {
  return <LandingBody />
}
