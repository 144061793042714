export const HumanFriendlyRoles = (role: string): string => {
  switch (role) {
    case 'strata_manager':
      return 'Strata Manager'
    case 'council':
      return 'Council'
    case 'owner':
      return 'Owner'
    case 'unit_owner_or_tenant':
      return 'Unit Owner or Tenant'
    default:
      return role.replaceAll('_', ' ')
  }
}
