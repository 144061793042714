import React, { useState } from 'react'
import { UnitResource } from '../../services/api/unitResource'
import { Form, useNavigate } from 'react-router-dom'
import { accountScoped } from '../../lib/url'
import { Button } from '@mui/material'
import { PageHeader } from '../../components/headings'
import { Float } from '../../components/float'
import { TextInput } from '../../components/form/textInput'

export const New = (): React.ReactNode => {
  const [title, setTitle] = useState<string>('')
  const [titleError, setTitleError] = useState<string>('')

  const navigate = useNavigate()
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    UnitResource.create({ title })
      .then((response) => {
        setTitle('')
        const { id } = response.data
        navigate(accountScoped(`units/${id}`))
      })
      .catch((error) => {
        console.error(error.response)
        if (error.response.status === 422) {
          if (error.response.data.title != null) {
            setTitleError(error.response.data.title[0] as string)
          }
        } else {
          setTitleError('Oops, something went wrong!')
        }
      })
  }

  return (
    <Float>
      <PageHeader title={'New Unit'} />
      <Form className={'mt-4'} onSubmit={handleSubmit}>
        <TextInput
          label={'Title'}
          onChange={(event) => {
            setTitle(event.target.value)
          }}
          value={title}
          error={titleError !== '' ? `Unit ${titleError}` : null}
        />
        <Button variant={'contained'} type="submit">
          Create
        </Button>
      </Form>
    </Float>
  )
}
