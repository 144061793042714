import React from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material'
import { Person } from '@mui/icons-material'
import dayjs from 'dayjs'
import { CommentResource } from '../services/api/commentResource'

// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-argument
dayjs.extend(require('dayjs/plugin/relativeTime'))

export interface CommentProps {
  canDelete?: boolean
  id: string
  content: string
  createdAt: string
  updatedAt: string
  commenter: {
    id: string
    name: string
    avatarUrl: string
  }
  // callback for when the comment is deleted
  onDelete?: (id: string) => void
}

export const Comment = (props: CommentProps): React.ReactNode => {
  const { canDelete = false } = props
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false)
  return (
    <div className={'flex gap-2 w-full mb-4'}>
      <div className={'commenter'}>
        <div className={'avatar'}>
          <Tooltip title={props.commenter.name}>
            {props.commenter.avatarUrl === '' ||
            props.commenter.avatarUrl === null ? (
              <Person />
            ) : (
              <img
                className={'rounded-full h-8 w-8'}
                src={props.commenter.avatarUrl}
                alt={props.commenter.name}
              />
            )}
          </Tooltip>
        </div>
      </div>
      <div>
        <div className={'text-xs text-gray-500'}>
          {
            // @ts-expect-error `to` might not be defined due to breaking changes from the the relative time plugin
            dayjs().to(dayjs(props.createdAt))
          }
        </div>
        <div className={'comment-content'}>{props.content}</div>
        {canDelete && (
          <div className={'leading-tight'}>
            <a
              href={''}
              className={
                'text-red-500 text-sm leading-tight border-b  border-b-2 border-b-red-300 shadow-sm'
              }
              onClick={(event) => {
                event.preventDefault()
                setDeleteDialogOpen(true)
              }}
            >
              Delete
            </a>
            <Dialog
              open={deleteDialogOpen}
              onClose={() => {
                setDeleteDialogOpen(false)
              }}
            >
              <DialogTitle>
                {' '}
                <Alert severity={'warning'}>Confirm Delete</Alert>
              </DialogTitle>
              <DialogContent>
                Are you sure you want to delete this comment?
                <div className={'flex gap-2'}>
                  <Button
                    variant={'contained'}
                    onClick={() => {
                      setDeleteDialogOpen(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'error'}
                    onClick={() => {
                      CommentResource.delete(props.id)
                        .then(() => {
                          setDeleteDialogOpen(false)
                          props.onDelete?.(props.id)
                        })
                        .catch((error) => {
                          console.error(error)
                        })
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </div>
    </div>
  )
}
