import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import classNames from 'classnames'
import { HomeModernIcon } from '@heroicons/react/16/solid'

const Navbar = (): React.ReactNode => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <header
      className="bg-gray-900 sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-3"
      style={{ flex: '0 1 auto' }}
    >
      <div className="flex items-center justify-between px-4 py-3 sm:p-0">
        <div>
          <Link to={'/'}>
            <HomeModernIcon className={'h-8 w-8 text-gray-500'} />
          </Link>
        </div>
        <div className="sm:hidden">
          <button
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            type="button"
            className="block text-gray-500 hover:text-white focus:text-white focus:outline-none"
          >
            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
              {isOpen && (
                <path
                  fillRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              )}
              {!isOpen && (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
        </div>
      </div>
      <nav
        className={classNames({
          block: isOpen,
          hidden: !isOpen,
          'px-2 pt-2 pb-4 sm:flex sm:p-0': true,
        })}
      >
        {/* TODO: uncomment after deciding if and when to release this page */}
        {/* <Link */}
        {/*  to={'/pricing'} */}
        {/*  className="mt-1 block px-2 py-1 text-white font-semibold rounded hover:bg-gray-800 sm:mt-0 sm:ml-2" */}
        {/* > */}
        {/*  Pricing */}
        {/* </Link> */}
        <Link
          to={'/login'}
          className="mt-1 block px-2 py-1 text-gray-300 font-semibold rounded hover:bg-gray-800 sm:mt-0 sm:ml-2"
        >
          Login
        </Link>
        <Link
          to={'/signup'}
          className="mt-1 block px-2 py-1 text-gray-300 font-semibold rounded hover:bg-gray-800 sm:mt-0 sm:ml-2"
        >
          Sign Up
        </Link>
      </nav>
    </header>
  )
}

const Footer = (): React.ReactNode => {
  // footer with links to terms of service and privacy policy
  return (
    <div style={{ flex: '0 1 40px' }}>
      <footer className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 py-4 overflow-hidden sm:px-6 lg:px-8">
          <nav
            className="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            <div className="px-5 py-2">
              <Link
                to={'/terms'}
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Terms of Service
              </Link>
            </div>
          </nav>
        </div>
      </footer>
    </div>
  )
}

export const GuestLayout = (): React.ReactNode => {
  return (
    <div className={'flex flex-col h-full'}>
      <Navbar />
      <div style={{ flex: '1 1 auto' }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}
