import { apiClient } from './base'

export interface Prospect {
  email: string
}
export const prospectResource = {
  create: async (prospect: Prospect) => {
    return await apiClient().post('/prospects', { prospect })
  },
}
