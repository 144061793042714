import React, { useState } from 'react'
import classNames from 'classnames'

export interface TabContainerProps {
  tabs: Array<{ label: string; content: React.ReactNode }>
}

export const TabContainer = ({ tabs }: TabContainerProps): React.ReactNode => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div>
      <div className={'mb-4'}>
        {tabs.map((tab, index) => {
          return (
            <div
              className={classNames({
                'border-b-2 border-blue-500': activeTab === index,
                'inline mr-2 pb-2 px-2': true,
                'cursor-pointer': activeTab !== index,
              })}
              key={index}
              onClick={() => {
                setActiveTab(index)
              }}
            >
              {tab.label}
            </div>
          )
        })}
      </div>
      <div className={'py-2'}>{tabs[activeTab].content}</div>
    </div>
  )
}
