import React, { useMemo, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/16/solid'

interface DropdownProps<T> {
  options: T[]
  keyFunction: (option: T) => string
  renderOption: (option: T) => React.ReactNode
  value: T | null
  onOptionChange: (option: T) => void
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const Dropdown = <T extends any>({
  options,
  renderOption,
  keyFunction,
  value,
  onOptionChange,
}: DropdownProps<T>): React.ReactNode => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentOption, setCurrentOption] = useState<T | null>(value)

  const handleOptionChange = (selectedOption: T): void => {
    setCurrentOption(selectedOption)
    onOptionChange(selectedOption)
    setIsOpen(false)
  }

  const optionsMenu = useMemo(() => {
    return options.map((option) => {
      return (
        <div
          className={'cursor-pointer px-2 py-2 hover:bg-gray-200'}
          key={keyFunction(option)}
          onClick={(event) => {
            handleOptionChange(option)
          }}
          style={{ whiteSpace: 'nowrap' }}
        >
          {renderOption(option)}
        </div>
      )
    })
  }, [options, currentOption])
  return (
    <div className={'relative'}>
      <span
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div
          className={
            'flex gap-1 border border-gray-200 shadow rounded-md items-center px-2 py-2'
          }
        >
          <div style={{ whiteSpace: 'nowrap' }}>
            {currentOption == null ? null : renderOption(currentOption)}
          </div>
          <div style={{ width: 24 }}>
            <ChevronDownIcon />
          </div>
        </div>
      </span>
      {isOpen && (
        <div
          className={
            'absolute border border-1 shadow rounded-md opacity-100 bg-gray-100 px-2 py-2'
          }
          style={{ zIndex: 100 }}
        >
          {optionsMenu}
        </div>
      )}
    </div>
  )
}
