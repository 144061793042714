// Layout.js
import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { type Account, AccountResource } from './services/api/accountResource'
import classNames from 'classnames'
import { accountScoped, accountUnscoped } from './lib/url'
import { useSession } from './lib/session'
import { Menu, MenuItem, Tooltip } from '@mui/material'
import { Person } from '@mui/icons-material'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { RoleCan } from './components/roleCan'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface NavigationLinkProps {
  target?: string
  label: string
  onClick?: () => void
  unstyled?: boolean
}

function NavigationLink({
  label,
  target,
  onClick,
  unstyled = false,
}: NavigationLinkProps): React.ReactNode {
  const navigate = useNavigate()
  // find the current route
  const currentRoute = accountUnscoped(window.location.pathname) === target
  const defaultClass = classNames({
    'text-left block px-2 py-1 no-underline cursor-pointer text-primary-50':
      true,
    'hover:bg-primary-700': true,
    'bg-primary-700': currentRoute,
  })
  return (
    <a
      className={unstyled == null || unstyled ? '' : defaultClass}
      onClick={
        target != null
          ? () => {
              navigate(target)
            }
          : onClick
      }
    >
      {label}
    </a>
  )
}

const Header = ({ account }: { account: Account }): React.ReactNode => {
  const navigate = useNavigate()
  const { currentUser } = useSession()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <header className={'p-4'}>
      <h1 className={'text-3xl text-left text-primary-50 mb-1 text-center'}>
        {account.name}
      </h1>
      <div
        className={
          'flex items-center text-gray-500 m-auto cursor-pointer justify-center'
        }
        onClick={handleClick}
      >
        <Tooltip title={currentUser?.name}>
          <div className={'rounded-full'}>
            {currentUser?.avatar_url != null ? (
              <img
                src={currentUser?.avatar_url}
                style={{ width: 24, height: 24 }}
              />
            ) : (
              <div>
                <Person style={{ width: 24, height: 24 }} />
              </div>
            )}
          </div>
        </Tooltip>
        <ChevronDownIcon style={{ width: 16, height: 16 }} />
      </div>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            navigate(accountScoped('profile'))
            handleClose()
          }}
        >
          My account
        </MenuItem>
        <MenuItem className={'bg-red-700'}>
          <NavigationLink
            unstyled
            onClick={() => {
              localStorage.setItem('Authorization', '')
              navigate('/login')
              handleClose()
            }}
            label={'Sign out'}
          />
        </MenuItem>
      </Menu>
    </header>
  )
}

const Sidenav = ({
  open,
  onToggle,
}: {
  open: boolean
  onToggle: () => void
}): React.ReactNode => {
  const [account, setAccount] = React.useState<any>(null)
  const { featureFlags } = useSession()

  useEffect(() => {
    AccountResource.show()
      .then((res) => {
        setAccount(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  if (account == null) {
    return null
  }

  const toggle = (
    <a
      href={'#'}
      className={'md:hidden block p-2 text-primary-50'}
      onClick={onToggle}
    >
      {!open && (
        <div className={'flex gap-4 flex-row align-middle'}>
          <div>
            <svg
              className={'h-6 w-6'}
              fill={'none'}
              viewBox={'0 0 24 24'}
              stroke={'currentColor'}
            >
              <path
                strokeLinecap={'round'}
                strokeLinejoin={'round'}
                strokeWidth={2}
                d={'M4 6h16M4 12h16M4 18h16'}
              />
            </svg>
          </div>
          <h1 className={'text-md text-primary-50 font-bold'}>
            {account.name}
          </h1>
        </div>
      )}
      {
        // close icon
        open && (
          <div>
            <svg
              className={'h-6 w-6'}
              fill={'none'}
              viewBox={'0 0 24 24'}
              stroke={'currentColor'}
            >
              <path
                strokeLinecap={'round'}
                strokeLinejoin={'round'}
                strokeWidth={2}
                d={'M6 18L18 6M6 6l12 12'}
              />
            </svg>
          </div>
        )
      }
    </a>
  )

  const nav = (
    <>
      {toggle}
      <div
        className={classNames({
          'hidden md:block': !open,
          'block md:hidden': open,
        })}
      >
        <Header account={account} />

        <hr className={'mb-4'} />

        <RoleCan allowedRoles={['strata_manager', 'council', 'owner']}>
          <NavigationLink target={'units'} label={'Units'} />
        </RoleCan>

        <RoleCan allowedRoles={['strata_manager', 'council', 'owner']}>
          <NavigationLink target={'users'} label={'Users'} />
        </RoleCan>

        {featureFlags?.documents === true && (
          <RoleCan allowedRoles={['strata_manager', 'council']}>
            <NavigationLink target={'documents'} label={'Documents'} />
          </RoleCan>
        )}

        <NavigationLink target={'parking_requests'} label={'Visitor Parking'} />

        <NavigationLink target={'issues'} label={'Issues'} />
      </div>
    </>
  )
  return <div className={'bg-primary-900 h-full'}>{nav}</div>
}

export const Layout = (): React.ReactNode => {
  const [navOpen, setNavOpen] = React.useState(false)
  return (
    <div className={'flex flex-col h-screen md:flex-row gap-4'}>
      <div className={'basis-0 md:basis-1/5'}>
        <Sidenav
          open={navOpen}
          onToggle={() => {
            setNavOpen(!navOpen)
          }}
        />
      </div>
      <div className={'basis-full overflow-y-scroll'}>
        <main className={'px-2'}>
          <Outlet />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </main>
      </div>
    </div>
  )
}
