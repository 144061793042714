import React from 'react'
import { DocumentResource } from '../../services/api/documentResource'

export const New = (): React.ReactNode => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    DocumentResource.create({
      name: (event.target as any).file.files[0].name,
      file: (event.target as any).file.files[0],
    })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error('Error creating document', error)
      })
  }
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" />
      </div>
      <div>
        <label htmlFor="file">File</label>
        <input type="file" name="file" id="file" />
      </div>
      <button type="submit">Create Document</button>
    </form>
  )
}
