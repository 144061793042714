import React, { useEffect, useState } from 'react'

import { PageHeader } from '../../components/headings'
import { Form, useNavigate } from 'react-router-dom'
import { Autocomplete, Button, TextField } from '@mui/material'
import { accountScoped } from '../../lib/url'
import { UserResource } from '../../services/api/userResource'
import { HumanFriendlyRoles } from '../../lib/roles'
import { Float } from '../../components/float'
import { TextInput } from '../../components/form/textInput'
import axios, { type AxiosError } from 'axios'

export const New = (): React.ReactNode => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null as string | null)

  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(null as string | null)

  const [roles, setRoles] = useState([] as string[])
  const [roleError, setRoleError] = useState(null as string | null)

  const [validRoles, setValidRoles] = useState([] as string[])

  const navigate = useNavigate()

  useEffect(() => {
    UserResource.roles()
      .then((response) => {
        setValidRoles(response.data.roles)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    UserResource.create({ email, name, roles })
      .then((response) => {
        setEmail('')
        setName('')
        setRoles([])
        navigate(accountScoped(`users`))
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          const e = error as AxiosError
          console.error(e.response)
          if (e.response?.status === 422) {
            const data = e.response?.data as {
              errors: Record<string, string[]>
            }
            if (data?.errors.email != null) {
              setEmailError(data?.errors.email[0])
            }
            if (data.errors.name != null) {
              setNameError(data.errors.name[0])
            }
            if (data.errors.roles != null) {
              console.error(data.errors.roles)
              setRoleError(data.errors.roles[0])
            }
          }
        } else {
          console.error(error)
        }
      })
  }

  return (
    <Float>
      <PageHeader title={'New User'} />
      <Form className={'mt-4'} onSubmit={handleSubmit}>
        <TextInput
          label={'Email'}
          value={email}
          onChange={(event) => {
            setEmail(event.target.value)
          }}
          error={emailError == null ? null : `Email ${emailError}`}
        />
        <TextInput
          label={'Name'}
          value={name}
          onChange={(event) => {
            setName(event.target.value)
          }}
          error={nameError == null ? null : `Name ${nameError}`}
        />
        <div className={'mb-4'}>
          <Autocomplete
            multiple
            value={roles}
            onChange={(event, value) => {
              setRoles(value)
            }}
            getOptionLabel={(option) => HumanFriendlyRoles(option)}
            renderInput={(params) => {
              return <TextField {...params} label={'Roles'} />
            }}
            options={validRoles}
            size={'small'}
            sx={{ width: 200 }}
          />
          {roleError == null ? null : (
            <p className={'text-red-500'}>Role {roleError}</p>
          )}
        </div>
        <Button variant={'contained'} type="submit">
          Create
        </Button>
      </Form>
    </Float>
  )
}
