import React from 'react'

export const WaitConfirmationEmail = (): React.ReactNode => {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="text-center">
        <div className="text-3xl font-bold mb-4">Check your email</div>
        <div className="text-lg mb-4">
          We sent you a confirmation email. Please click the link in the email
          to confirm your account.
        </div>
        <div className="text-lg mb-4">
          If you don&apos;t see the email, check your spam folder.
        </div>
      </div>
    </div>
  )
}
