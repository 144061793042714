/* eslint-disable @typescript-eslint/unbound-method */
import axios, { type AxiosInstance } from 'axios'

export const getBaseURL = (): string => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'http://localhost:3000'
    case 'production':
      return 'https://kondomax-api-d935937fa89c.herokuapp.com'
    default:
      return 'http://localhost:3000'
  }
}

export const apiClient = (): AxiosInstance =>
  axios.create({
    baseURL: getBaseURL(),
    timeout: 1000,
    headers: {
      Authorization: localStorage.getItem('Authorization'),
      Accept: '*/*',
    },
  })
