import React from 'react'

export const Float = (props: React.PropsWithChildren): React.ReactNode => {
  return (
    <div
      className="px-10 py-5 m-auto bg-white rounded-lg border border-gray-50 shadow shadow-gray-50"
      style={{ maxWidth: 1000 }}
    >
      {props.children}
    </div>
  )
}
