import React from 'react'
import { useSession } from '../lib/session'

type AllowedRoles =
  | 'owner'
  | 'council'
  | 'unit_owner_or_tenant'
  | 'strata_manager'

export const RoleCan = ({
  allowedRoles,
  children,
}: React.PropsWithChildren<{
  allowedRoles: AllowedRoles[]
}>): React.ReactNode => {
  const { currentUser } = useSession()
  const userRoles = currentUser?.roles ?? []
  const userRolesSet = new Set(userRoles)
  const intersection = new Set(
    [...allowedRoles].filter((x) => userRolesSet.has(x)),
  )
  if (intersection.size > 0) {
    return <>{children}</>
  }
  return <></>
}
