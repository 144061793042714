import { TableCell } from '@mui/material'
import React from 'react'

const CustomTableCell = ({ label }: { label: string }): React.ReactNode => {
  return (
    <TableCell>
      <span className={'font-bold'}>{label}</span>
    </TableCell>
  )
}

export { CustomTableCell }
