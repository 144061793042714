import React, { useCallback, useEffect, useState } from 'react'
import {
  type Comment,
  CommentResource,
} from '../../services/api/commentResource'
import { Comment as CommentDisplay } from '../../components/comment'
import { Button } from '@mui/material'
import { Form } from 'react-router-dom'
import { type AxiosError } from 'axios'
import { useSession } from '../../lib/session'

export interface CommentProps
  extends Pick<
    Comment,
    'id' | 'content' | 'updated_at' | 'created_at' | 'commenter'
  > {}

interface CommentFormProps {
  onCommentCreated: (content: string) => void
}

const CommentForm = ({
  onCommentCreated,
}: CommentFormProps): React.ReactNode => {
  const [content, setContent] = useState<string>('')
  const clearForm = (): void => {
    setContent('')
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    onCommentCreated(content)
    clearForm()
  }
  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <textarea
          className={'text-left border border-2 w-1/2'}
          name="content"
          id="content"
          value={content}
          onChange={(e) => {
            setContent(e.target.value)
          }}
        />
      </div>
      <Button
        variant={'contained'}
        type="submit"
        disabled={content?.length <= 0}
      >
        Submit
      </Button>
    </Form>
  )
}

interface CommentsBlockProps {
  commentableId: string
  commentableType: string
}

export const CommentsBlock = (props: CommentsBlockProps): React.ReactNode => {
  const { commentableId, commentableType } = props
  const [comments, setComments] = useState<Comment[]>([] as Comment[])
  const { currentUser } = useSession()

  const refreshComments = useCallback((): void => {
    CommentResource.index({
      commentableId,
      commentableType,
    })
      .then((res) => {
        setComments(res.data.data)
      })
      .catch((err: AxiosError) => {
        console.error(err)
      })
  }, [commentableId, commentableType])

  useEffect(() => {
    refreshComments()
  }, [refreshComments])

  const onCommentCreated = (content: string): void => {
    CommentResource.create({
      content,
      commentable_id: commentableId,
      commentable_type: commentableType,
    })
      .then((res) => {
        refreshComments()
      })
      .catch((err: AxiosError) => {
        console.error(err)
      })
  }

  return (
    <div style={{ width: 800 }}>
      <h1>Comments</h1>
      <CommentForm onCommentCreated={onCommentCreated} />
      {comments.map((comment: Comment) => {
        return (
          <CommentDisplay
            key={comment.id}
            canDelete={comment.commenter.id === currentUser?.id}
            commenter={{
              id: comment.commenter.id,
              name: comment.commenter.name,
              avatarUrl: comment.commenter.avatar_url,
            }}
            id={comment.id}
            content={comment.content}
            createdAt={comment.created_at}
            updatedAt={comment.updated_at}
            onDelete={(id) => {
              setComments(comments.filter((comment) => comment.id !== id))
            }}
          />
        )
      })}
    </div>
  )
}
