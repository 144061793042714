import React from 'react'
import { Float } from '../../components/float'
import { PageHeader } from '../../components/headings'
import { IssueForm } from './issueForm'

export const New = (): React.ReactNode => {
  return (
    <Float>
      <PageHeader title={'New Issue'} />
      <IssueForm issue={null} />
    </Float>
  )
}
