import { accountScoped } from '../../lib/url'
import { apiClient } from './base'

type Status = 'pending' | 'in_progress' | 'resolved'

export interface AssignedTo {
  id: string
  name: string
  avatar_url: string | null
}

export interface Reporter {
  id: string
  name: string
  avatar_url: string | null
}

export interface Issue {
  id: string
  title: string
  description: string
  status: Status
  images: Array<{ id: string; url: string }>
  created_at: string
  assigned_to: AssignedTo | null
  reporter: Reporter
  reporter_units: string[] | null
}

export interface NewIssue {
  title: string
  description: string
  images?: File[]
}

export interface UpdateIssue {
  title: string
  description: string
  status?: string
  // it's a File for new images and an object with id and url for existing images
  images: Array<File | { id: string; url: string }>
  assigned_to_id: string | null
}

export interface IssuesResponse {
  data: Issue[]
  meta: { pagination: { count: number; prev_url: string; next_url: string } }
}

export const IssueResource = {
  index: async (params?: Record<string, any>) =>
    await apiClient().get<IssuesResponse>(accountScoped('issues'), { params }),
  show: async (id: string) =>
    await apiClient().get<Issue>(
      accountScoped('issues/:id').replace(':id', id),
    ),
  create: async (data: NewIssue) => {
    const formData = new FormData()
    formData.append('issue[description]', data.description)
    if (data.title !== null && data.title !== undefined)
      formData.append('issue[title]', data.title)
    if (data.images !== null && data.images !== undefined) {
      data.images.forEach((image, index) => {
        formData.append('issue[images][]', image)
      })
    }
    return await apiClient().post(accountScoped('issues'), formData)
  },
  update: async (id: string, issue: UpdateIssue) => {
    const formData = new FormData()
    if (issue.description != null) {
      formData.append('issue[description]', issue.description)
    }
    if (issue.title != null) {
      formData.append('issue[title]', issue.title)
    }
    if (issue.status != null) {
      formData.append('issue[status]', issue.status)
    }
    if (issue.images != null) {
      // set an empty element to ensure that all images are removed if the user deletes all images
      formData.append('issue[images][]', '')
      issue.images.forEach((image, index) => {
        const img = image instanceof File ? image : image.id
        formData.append('issue[images][]', img)
      })
    }
    if (issue.assigned_to_id != null) {
      formData.append('issue[assigned_to_id]', issue.assigned_to_id)
    }
    return await apiClient().put(accountScoped(`issues/${id}`), formData)
  },
}
