import React, { useEffect, useState } from 'react'
import { type Account, AccountResource } from '../services/api/accountResource'
import { useNavigate } from 'react-router-dom'

export const Accounts = (): React.ReactNode => {
  const [accounts, setAccounts] = useState<Account[] | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    AccountResource.index()
      .then((response) => {
        setAccounts(response.data.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  if (accounts == null) {
    return 'Loading...'
  }

  if (accounts.length === 1) {
    navigate(`/accounts/${accounts[0].url_token}`)
  }

  return (
    <div className={'h-screen flex justify-center items-center'}>
      <div className=" border shadow rounded-md" style={{ width: 400 }}>
        <div className={'text-lg text-left bg-gray-100 rounded-t px-2'}>
          My Accounts
        </div>
        {accounts.map((account: any) => {
          return (
            <a
              className={
                'cursor-pointer text-left px-2 py-1 block underline text-primary-500 border border-2'
              }
              key={account.url_token}
              href={`/accounts/${account.url_token}`}
            >
              {account.name}
            </a>
          )
        })}
      </div>
    </div>
  )
}
