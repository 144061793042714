import React from 'react'
import { Float } from '../components/float'

export const Terms = (): React.ReactNode => {
  return (
    <Float>
      <h1 className={'text-xl'}>Terms and Conditions for KondoMax</h1>
      <div> Last Updated: 2023-01-23</div>
      <div>
        <section>
          <div className={'text-lg'}>1. Acceptance of Terms</div>
          <p>
            By accessing or using this website (kondomax.com) and the associated
            apps (the &ldquo;Service&ldquo;), you agree to comply with and be
            bound by these Terms and Conditions. If you do not agree with these
            terms, please do not use the Service.
          </p>
        </section>
        <section>
          <div className={'text-lg'}>2. Use of the Service</div>
          <p>
            <strong>2.1. License</strong>: Subject to these Terms, we grant you
            a limited, non-exclusive, non-transferable, and revocable license to
            use the Service for managing strata properties.
          </p>
          <p>
            <strong> 2.2. User Accounts</strong>: You may be required to create
            an account to use certain features of the Service. You are
            responsible for maintaining the confidentiality of your account
            information and for all activities that occur under your account.
          </p>
        </section>
        <section>
          <div className={'text-lg'}>3. Data Privacy</div>
          <p>
            <strong>3.1. Data Collection</strong>: We may collect and process
            personal and property-related data to provide the Service. Our
            Privacy Policy governs the collection, use, and disclosure of such
            information.
          </p>
          <p>
            <strong>3.2. Data Ownership</strong>: You retain ownership of your
            data. By using the Service, you grant us the right to use, process,
            and store your data in accordance with our Privacy Policy.
          </p>
        </section>
        <section>
          <div className={'text-lg'}>4. Fees and Payments</div>
          <p>
            <strong>4.1. Subscription Fees</strong>: The use of certain features
            may be subject to subscription fees. You agree to pay all applicable
            fees as described on our pricing page.
          </p>
          <p>
            <strong>4.2. Payment</strong>: Payments are due in accordance with
            the selected billing cycle. Failure to pay may result in the
            suspension or termination of your account.
          </p>
        </section>
        <section>
          <div className={'text-lg'}>5. Intellectual Property</div>
          <p>
            The Service, including but not limited to software, trademarks, and
            content, is the property of ByteBrew Technologies Ltd.. You agree
            not to reproduce, modify, or distribute any part of the Service
            without our express written consent.
          </p>
        </section>
        <section>
          <div className={'text-lg'}>6. Limitation of Liability</div>
          <p>
            To the maximum extent permitted by law, ByteBrew Technologies Ltd.
            and its affiliates shall not be liable for any indirect, incidental,
            special, consequential, or punitive damages, or any loss of profits
            or revenues.
          </p>
        </section>
        <section>
          <div className={'text-lg'}>7. Termination</div>
          <p>
            We reserve the right to suspend or terminate your access to the
            Service at any time for any reason without notice.
          </p>
        </section>
        <section>
          <div className={'text-lg'}>8. Governing Law</div>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of the province of British Columbia, Canada.
          </p>
        </section>
        <section>
          <div className={'text-lg'}>9. Changes to Terms</div>
          <p>
            We reserve the right to modify these Terms at any time. Changes will
            be effective upon posting. Continued use of the Service after
            changes constitute acceptance of the modified terms.
          </p>
        </section>
        <section>
          <div className={'text-lg'}>10. Contact Information</div>
          <p>
            For any questions or concerns regarding these Terms, please contact
            us at kondomax.com@gmail.com.
          </p>
        </section>
      </div>
    </Float>
  )
}
