import React from 'react'

export const StatusColors = {
  pending: 'bg-red-500',
  in_progress: 'bg-yellow-500',
  resolved: 'bg-green-500',
}

export const StatusLabel = ({
  status,
}: {
  status: 'pending' | 'in_progress' | 'resolved'
}): React.ReactNode => {
  const statuses = {
    pending: 'Pending',
    in_progress: 'In Progress',
    resolved: 'Resolved',
  }

  return (
    <div className={'flex items-center'}>
      <div
        className={`rounded-full w-3 h-3 mr-2 ${StatusColors[status]}`}
      ></div>
      <div> {statuses[status]}</div>
    </div>
  )
}
