import React, { useEffect, useState } from 'react'
import {
  type ParkingRequest,
  ParkingRequestResource,
} from '../../services/api/parkingRequest'
import { TabContainer } from '../../components/tabs'
import { PageHeader } from '../../components/headings'
import { accountScoped } from '../../lib/url'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { TextInput } from '../../components/form/textInput'
import dayjs from 'dayjs'

const ParkingCard = (props: {
  parkingRequest: ParkingRequest
}): React.ReactNode => {
  return (
    <div
      className={
        'border border-2 p-2 mb-4 rounded-lg border-gray-100 shadow-gray-100 shadow-sm'
      }
    >
      <div className={'flex justify-between'}>
        <div className={'font-bold'}>{props.parkingRequest.license_plate}</div>
      </div>
      <div className={'flex'}>
        <div className={'text-gray-500'}>
          {dayjs(Date.parse(props.parkingRequest.start_at)).format(
            'YYYY-MM-DD',
          )}
        </div>
      </div>
    </div>
  )
}

export interface IndexQueryParams {
  active?: boolean
  upcoming?: boolean
  recent_parked?: boolean
  q?: string
}

function ParkingRequestTab(props: {
  queryParams: IndexQueryParams
}): React.ReactNode {
  const [parkingRequests, setParkingRequests] = useState([] as ParkingRequest[])

  useEffect(() => {
    // @ts-expect-error Record<string, unknown> is not assignable to IndexQueryParams
    ParkingRequestResource.index(props.queryParams)
      .then((response) => {
        setParkingRequests(response.data.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [props.queryParams])
  return (
    <div style={{ width: 600 }}>
      {parkingRequests.length === 0 ? (
        <div>No Parking Requests</div>
      ) : (
        parkingRequests.map((parkingRequest: ParkingRequest) => {
          return (
            <ParkingCard
              key={parkingRequest.id}
              parkingRequest={parkingRequest}
            />
          )
        })
      )}
    </div>
  )
}

export const Index = (): React.ReactNode => {
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState('')

  return (
    <>
      <PageHeader title={'Visitor Parking'} />
      <Button
        onClick={() => {
          navigate(accountScoped('parking_requests/new'))
        }}
      >
        Register License Plate
      </Button>
      <TextInput
        label={'Search License Plate'}
        value={searchText}
        onChange={(event) => {
          setSearchText(event.target.value)
        }}
      />
      <div>
        <TabContainer
          tabs={[
            {
              label: 'Active',
              content: (
                <ParkingRequestTab
                  queryParams={{ active: true, q: searchText }}
                />
              ),
            },
            {
              label: 'Upcoming',
              content: (
                <ParkingRequestTab
                  queryParams={{ upcoming: true, q: searchText }}
                />
              ),
            },
            {
              label: 'Recently Parked',
              content: (
                <ParkingRequestTab
                  queryParams={{ recent_parked: true, q: searchText }}
                />
              ),
            },
          ]}
        />
      </div>
    </>
  )
}
