import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { type Issue, IssueResource } from '../../services/api/issueResource'
import { PageHeader } from '../../components/headings'
import { Float } from '../../components/float'
import { IssueForm } from './issueForm'

export const Show = (): React.ReactNode => {
  const { id } = useParams()
  const initialIssue = null
  const [issue, setIssue] = useState<Issue | null>(initialIssue)

  useEffect(() => {
    if (id != null) {
      IssueResource.show(id)
        .then((res) => {
          setIssue(res.data)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [id])

  if (id == null || issue == null) return <div>Issue not found</div>

  return (
    <Float>
      <PageHeader title={'Issue'} />
      <IssueForm issue={issue} />
    </Float>
  )
}
