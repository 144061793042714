import React from 'react'
import { Float } from '../../components/float'
import { PageHeader } from '../../components/headings'
import { Form, useNavigate } from 'react-router-dom'
import { ParkingRequestResource } from '../../services/api/parkingRequest'
import { type AxiosError } from 'axios'
import { Button, TextField } from '@mui/material'
import { TextInput } from '../../components/form/textInput'

import dayjs from 'dayjs'
import { accountScoped } from '../../lib/url'

export const New = (): React.ReactNode => {
  const [licensePlate, setLicensePlate] = React.useState('')
  const [startAt, setStartAt] = React.useState(dayjs().format('YYYY-MM-DD'))
  const navigate = useNavigate()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    ParkingRequestResource.create({
      license_plate: licensePlate,
      start_at: dayjs(startAt).format(),
    })
      .then((response) => {
        navigate(accountScoped('/parking_requests'))
      })
      .catch((error: AxiosError) => {
        console.error(error.response)
      })
  }

  return (
    <Float>
      <PageHeader title={'Register License Plate'} />
      <Form className={'mt-4'} onSubmit={handleSubmit}>
        <TextInput
          label={'License Plate'}
          onChange={(event) => {
            setLicensePlate(event.target.value?.toUpperCase())
          }}
          value={licensePlate}
        />
        <div className={'mb-4'}>
          <TextField
            type={'date'}
            label={'Date'}
            onChange={(event) => {
              setStartAt(event.target.value)
            }}
            value={startAt}
            size={'small'}
            InputLabelProps={{ shrink: true }}
            sx={{ width: 200 }}
          />
        </div>
        <Button variant={'contained'} type={'submit'}>
          Submit
        </Button>
      </Form>
    </Float>
  )
}
