import React, { useEffect, useState } from 'react'
import {
  type Document,
  DocumentResource,
} from '../../services/api/documentResource'
import { PageHeader } from '../../components/headings'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const Index = (): React.ReactNode => {
  const [documents, setDocuments] = useState<Document[] | null>(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    DocumentResource.index()
      .then((response) => {
        setDocuments(response.data.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching documents', error)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  if (documents == null) {
    return <div>No documents found</div>
  }

  return (
    <div>
      <PageHeader title={'Documents'} />
      <Button
        className={'col-span-12 text-left'}
        onClick={() => {
          navigate('new')
        }}
      >
        New Document
      </Button>
      <ul>
        {documents.map((document) => (
          <li key={document.id}>
            <a href={document.url}>{document.name}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}
