import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { LoginForm } from './pages/LoginForm'
import { Accounts } from './pages/Accounts'
import { Layout } from './layout'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { Index as ParkingRequestsIndex } from './resources/parking_requests'
import { New as ParkingRequestsNew } from './resources/parking_requests/new'

import { Index as IssueIndex } from './resources/issues'
import { Show as IssueShow } from './resources/issues/show'
import { New as IssueNew } from './resources/issues/new'

import { Index as UnitsIndex } from './resources/units'
import { New as UnitNew } from './resources/units/new'
import { Edit as UnitEdit } from './resources/units/edit'

import { Index as UserIndex } from './resources/users'
import { New as NewUser } from './resources/users/new'
import { Edit as EditUser } from './resources/users/edit'
import { EditProfile } from './resources/users/editProfile'

import { Index as DocumentsIndex } from './resources/documents'
import { Show as DocumentShow } from './resources/documents/show'
import { New as DocumentNew } from './resources/documents/new'

import { SessionProvider } from './lib/session'
import { Terms } from './pages/Terms'
import { GuestLayout } from './pages/GuestLayout'
import { Signup } from './pages/Signup'
import { Pricing } from './pages/Pricing'

import ReactGA from 'react-ga4'
import { WaitConfirmationEmail } from './signup/waitConfirmationEmail'
import { ConfirmationForm } from './signup/confirmation_form'

import * as Sentry from '@sentry/react'

if (process.env.NODE_ENV === 'production' || process.env.DEBUG_GA === 'true') {
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID
  if (trackingId == null || trackingId.includes('%')) {
    console.warn('Google Analytics is disabled')
  } else {
    ReactGA.initialize(trackingId)
  }
} else {
  ReactGA.initialize('G-FH6ESDLFZE')
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/kondomax\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

if ('serviceWorker' in navigator) {
  console.log('trying to register service worker')
  navigator.serviceWorker
    .register('/service-worker.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope)
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error)
    })
}

const router = createBrowserRouter([
  { path: '/accounts', element: <Accounts /> },
  {
    path: '/',
    element: <GuestLayout />,
    children: [
      // TODO: do we want to show the prices right away?
      { path: '/pricing', element: <Pricing /> },
      { path: '/terms', element: <Terms /> },
      { path: '/login', element: <LoginForm /> },
      { path: '/signup', element: <Signup /> },
      { path: '/signup/confirm', element: <ConfirmationForm /> },
      { path: '/signup/success', element: <WaitConfirmationEmail /> },
      { path: '/', element: <App /> },
    ],
  },
  {
    path: '/accounts/:url_token',
    element: <Layout />,
    children: [
      // parking requests
      { path: 'parking_requests', element: <ParkingRequestsIndex /> },
      {
        path: 'parking_requests/:id',
        element: <div>parking_requests/:id</div>,
      },
      { path: 'parking_requests/new', element: <ParkingRequestsNew /> },
      // issues
      { path: 'issues', element: <IssueIndex /> },
      { path: 'issues/:id', element: <IssueShow /> },
      { path: 'issues/new', element: <IssueNew /> },
      { path: 'issues/:id/edit', element: <div>issues/:id/edit</div> },
      // units
      { path: 'units', element: <UnitsIndex /> },
      { path: 'units/:id', element: <UnitEdit /> },
      { path: 'units/new', element: <UnitNew /> },
      // documents
      { path: 'documents', element: <DocumentsIndex /> },
      { path: 'documents/new', element: <DocumentNew /> },
      { path: 'documents/:id', element: <DocumentShow /> },
      // users
      { path: 'users', element: <UserIndex /> },
      { path: 'users/new', element: <NewUser /> },
      { path: 'users/:id/edit', element: <EditUser /> },
      // current user profile
      { path: 'profile', element: <EditProfile /> },
    ],
  },
])

const rootEl = document.getElementById('root')
if (rootEl == null) throw new Error('no root element')
const root = ReactDOM.createRoot(rootEl)

root.render(
  <React.StrictMode>
    <SessionProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </SessionProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
