import React from 'react'
import { IconButton } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import classNames from 'classnames'

const ImageModal = ({
  open,
  src,
  alt,
  onClick,
}: {
  open: boolean
  src: string
  alt: string
  onClick: () => void
}): React.ReactNode => {
  return (
    <div
      className={classNames({
        'fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50': true,
        hidden: !open,
      })}
      onClick={(event) => {
        event.stopPropagation()
        onClick()
      }}
    >
      <div
        className={
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        }
      >
        <img src={src} alt={alt} style={{ maxHeight: 500, maxWidth: 500 }} />
      </div>
    </div>
  )
}

export interface ImageAttachmentProps {
  src: string
  alt: string
  onDelete?: () => void
}

export const ImageAttachment = ({
  src,
  alt,
  onDelete,
}: ImageAttachmentProps): React.ReactNode => {
  const [isHovering, setIsHovering] = React.useState(false)
  const [modalOpen, setModalOpen] = React.useState(false)

  return (
    <div
      className={'relative inline-block'}
      onMouseEnter={() => {
        setIsHovering(true)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
      }}
      onClick={() => {
        setModalOpen(true)
      }}
    >
      {isHovering && onDelete != null && (
        <div className={'absolute inline-block -right-2.5 -top-2.5'}>
          <IconButton onClick={onDelete}>
            <Cancel />
          </IconButton>
        </div>
      )}
      <img
        src={src}
        alt={alt}
        style={{ maxHeight: 100, maxWidth: 200 }}
        className={'border border-gray-100 p-2 inline'}
      />
      <ImageModal
        open={modalOpen}
        src={src}
        alt={alt}
        onClick={() => {
          setModalOpen(false)
        }}
      />
    </div>
  )
}
