import React, {
  createContext,
  useContext,
  useState,
  type ReactNode,
  useEffect,
  useCallback,
} from 'react'
import { accountScoped, extractUrlToken } from './url'
import { apiClient } from '../services/api/base'

interface User {
  id: string
  name: string
  avatar_url: string | null
  roles: string[]
}

interface Account {
  id: string
  name: string
}

interface SessionContextType {
  currentUser: User | undefined
  currentAccount: Account | undefined
  featureFlags: FeatureFlag | undefined
}

interface FeatureFlag {
  push_notifications: boolean
  documents: boolean
}

interface CurrentResponse {
  user: User | undefined
  feature_flags: FeatureFlag | undefined
}

interface Session {
  current: (CurrentResponse & { account: Account }) | undefined
}

const SessionContext = createContext<SessionContextType | undefined>(undefined)

export const SessionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentSession, setCurrentSession] = useState<Session | undefined>(
    undefined,
  )
  const auth = localStorage.getItem('Authorization')

  const setSession = useCallback(async () => {
    try {
      // Fetch user data
      const currentResponse = await apiClient().get<CurrentResponse>(
        accountScoped('/users/current'),
      )
      // Fetch account data
      const accountResponse = await apiClient().get(
        `/accounts/${extractUrlToken()}`,
      )
      const fetchedSessionData = {
        current: {
          ...currentResponse.data,
          account: accountResponse.data,
        },
      } as const
      setCurrentSession(fetchedSessionData)
    } catch (error) {
      // Handle errors appropriately
      console.error('Error fetching session data:', error)
    }
  }, [auth])

  useEffect(() => {
    // Fetch initial session data when the component mounts
    setSession()
      .then(() => {})
      .catch((err) => {
        console.error(err)
      })
  }, [setSession])

  return (
    <SessionContext.Provider
      value={{
        currentUser: currentSession?.current?.user,
        currentAccount: currentSession?.current?.account,
        featureFlags: currentSession?.current?.feature_flags,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export const useSession = (): SessionContextType => {
  const context = useContext(SessionContext)

  if (context == null) {
    throw new Error('useSession must be used within a SessionProvider')
  }

  return context
}
